import React, { useState, useEffect } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { Avatar, Menu, MenuItem, Tooltip, Box, Divider } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import StorageIcon from '@mui/icons-material/Storage';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useLocation, useNavigate } from 'react-router-dom';
import { startLogout } from '../../redux/auth/thunk';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ListwithTitle } from './ListwithTitle';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import TableChartIcon from '@mui/icons-material/TableChart';
import PeopleIcon from '@mui/icons-material/People';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GroupIcon from '@mui/icons-material/Group';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ComputerIcon from '@mui/icons-material/Computer';
import { cleanTenantData } from '../../redux/tenant/thunk';
import { cleanCPanelData } from '../../redux/cpanel/thunk';
import { getTablesNames } from '../../redux/tables/thunk';
import { Section } from './Models';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { AddAutoRule } from './AddComponents/AddAutoRule';
import { AddTable } from './AddComponents/AddTable';
import { AddReport } from './AddComponents/AddReport';
import { getAutorulesnames } from '../../redux/autoRules/thunk';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import PaymentsIcon from '@mui/icons-material/Payments';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { getPermissions } from '../../redux/permission/thunk';
import LabelIcon from '@mui/icons-material/Label';
import { getDashboardsNames } from '../../redux/dashboards/thunk';
import { AddDashboard } from './AddComponents/AddDashboard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { getReportsNames } from '../../redux/reports/thunk';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import axios from 'axios';
import NotificationsIcon from '@mui/icons-material/Notifications';

const drawerWidth = 240;
const url = process.env.REACT_APP_NEXUS_BACK_URL;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});


interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(!open && {
        width: `calc(100% - ${closedMixin(theme).width} - 8px)`,
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${closedMixin(theme).width} - 0px)`,
        },
    })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


type Props = {
    children: React.ReactNode;
};
export default function MiniDrawer({ children }: Props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const location = useLocation()
    const { status, name, profileImage, lastname, id, tenantId, isOwner, suscription, token } = useAppSelector(state => state.auth)
    const { dashboards } = useAppSelector(state => state.dashboards)
    const [updateLinks, setUpdateLinks] = useState<{ version: string; link: string }[]>([]);
    const [anchorElUpdate, setAnchorElUpdate] = React.useState<null | HTMLElement>(null);
    const [imageSrc, setImageSrc] = React.useState('');
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const handleOpenUpdates = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUpdate(event.currentTarget);
    };
    
      const handleCloseUpdateMenu = () => {
        setAnchorElUpdate(null);
    };
    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleLogout = () => {
        window.localStorage.removeItem("jwt")
        window.localStorage.removeItem("tenantId")
        dispatch(startLogout());
        dispatch(cleanTenantData());
        dispatch(cleanCPanelData())
    }

    const handleBackends = () => {
        window.localStorage.removeItem("tenantId")
        window.location.href= "/tenant-selector"
        dispatch(cleanTenantData());
        dispatch(cleanCPanelData())
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    React.useEffect(() => {

        if (profileImage != undefined) {

            const blob = new Blob([profileImage], { type: 'image/jpeg' });
            const imageUrl = URL.createObjectURL(blob);

            setImageSrc(imageUrl)
        }
    }, [profileImage])

    //dynamics tables
    const { tablesName: tablesNameState } = useAppSelector(state => state.tables)
    const { autoruleNames: autoRulesNameState } = useAppSelector(state => state.autoRules)
    const { permissions } = useAppSelector(state => state.users)
    const { permissionsList } = useAppSelector(state => state.permissions)

    const [tablesName, setTablesName] = useState<any>(tablesNameState)
    const [autoRulesName, setAutoRulesName] = useState<any>(autoRulesNameState)
    const [dashboardsName, setDashboardsName] = useState<any>([])
    const [reportsName, setReportsName] = useState<any>([])

    //open add table
    const [openAddTable, setOpenAddTable] = useState(false)

    //open add autorule
    const [openAddAutoRule, setOpenAddAutoRule] = useState(false)

    //open add dashboard
    const [openAddDashboard, setOpenAddDashboard] = useState(false)

    //open add report
    const [openAddReport, setOpenAddReport] = useState(false)

    useEffect(() => {
        dispatch(getTablesNames(tenantId))
        dispatch(getAutorulesnames(tenantId))
        dispatch(getDashboardsNames(tenantId))
        dispatch(getReportsNames(tenantId))
    }, [])

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${url}/api/v1/NexusUpdate/Latest`);
  
            if (response.status === 200 && response.data.success) {
              const links: { version: string; link: string }[] = [];
              response.data.result.forEach((result: any) => {
                result.updateContents.forEach((updateContent: any) => {
                  links.push({ version: result.nexusUpdate.version, link: updateContent.updateLink });
                });
              });
              setUpdateLinks(links);
            }
          } catch (error) {
  
          }
        };
    
        fetchData();
      }, []);

    useEffect(() => {
        if (permissionsList) {
            let tableNameItems: any = []
            permissionsList.backend.tables.forEach((tableData) => {


                if (tableData.role === '' && tableData.permissions.length === 0) {
                } else {

                    tableNameItems = [
                        ...tableNameItems,
                        {
                            path: `/${tenantId}/tables/dynamicTable/${tableData.id}`,

                            name: tableData.name
                        },
                    ]
                }
            })
            if (permissionsList?.backend.roles.includes("CanManageTables")) {
                tableNameItems = [
                    ...tableNameItems,
                    {
                        name: <IconButton onClick={() => setOpenAddTable(true)} sx={{
                            ":hover": {
                                backgroundColor: "#6a7187"
                            }
                        }}>
                            <AddIcon sx={{ display: "flex", justifyContent: "center", color: "#9fa5b5" }} />
                        </IconButton>
                    }
                ]
            }
            setTablesName(tableNameItems)

            let reportsItems: any = []
            permissionsList.backend.reports.forEach((reportData) => {
                if (reportData.permissions.includes("CanRead") || reportData.role === 'CanManageReports') {
                    reportsItems = [
                        ...reportsItems,
                        {
                            path: `/${tenantId}/report/${reportData.id}`,

                            name: reportData.name
                        },
                    ]
                }
            })
            if (permissionsList?.backend.roles.includes("CanManageReports")) {
                reportsItems = [
                    ...reportsItems,
                    {
                        name: <IconButton onClick={() => setOpenAddReport(true)} sx={{
                            ":hover": {
                                backgroundColor: "#6a7187"
                            }
                        }}>
                            <AddIcon sx={{ display: "flex", justifyContent: "center", color: "#9fa5b5" }} />
                        </IconButton>
                    }
                ]
            }
            setReportsName(reportsItems)

            let tableAutorulesItems: any = []
            permissionsList.backend.autorules.forEach((autoRuleData) => {
                if (autoRuleData.permissions.includes("CanRead") || autoRuleData.role === 'CanManageAutorules') {

                    tableAutorulesItems = [
                        ...tableAutorulesItems,
                        {
                            path: `/${tenantId}/autorules/${autoRuleData.id}`,
                            name: autoRuleData.name
                        },
                    ]
                }
            })

            if (permissionsList?.backend.roles.includes("CanManageAutorules")) {

                tableAutorulesItems = [
                    ...tableAutorulesItems,
                    {
                        name: <IconButton onClick={() => setOpenAddAutoRule(true)} sx={{
                            ":hover": {
                                backgroundColor: "#6a7187"
                            }
                        }}>
                            <AddIcon sx={{ display: "flex", justifyContent: "center", color: "#9fa5b5" }} />
                        </IconButton>
                    }
                ]
            }

            setAutoRulesName(tableAutorulesItems)
            if (permissionsList.backend.roles.includes('CanManageRoles')) {
                setSimpleItemsDrawer([{
                    icon: < AdminPanelSettingsIcon />,
                    name: "Roles",
                    path: `/${tenantId}/roles`,
                    authorizedSubscriptions: [
                        process.env.REACT_APP_BUSINESS_ID as string,
                        process.env.REACT_APP_STARTUP_ID as string,
                        process.env.REACT_APP_ENTERPRISE_ID as string,
                        process.env.REACT_APP_CORPORATION_ID as string,
                        process.env.REACT_APP_FREELANCER_ID as string
                    ]
                }])

            }
        }
    }, [permissionsList])

    useEffect(() => {

        let tableAutorulesItems: any = []
        dashboards.forEach((dashboard) => {
            tableAutorulesItems = [
                ...tableAutorulesItems,
                {
                    path: `/${tenantId}/dashboard/${dashboard.id}`,
                    name: dashboard.name
                },
            ]

        })



        tableAutorulesItems = [
            ...tableAutorulesItems,
            {
                name: <IconButton onClick={() => setOpenAddDashboard(true)} sx={{
                    ":hover": {
                        backgroundColor: "#6a7187"
                    }
                }}>
                    <AddIcon sx={{ display: "flex", justifyContent: "center", color: "#9fa5b5" }} />
                </IconButton>
            }
        ]


        setDashboardsName(tableAutorulesItems)
    }, [dashboards])

    const [simpleItemsDrawer, setSimpleItemsDrawer] = useState<any[]>([])

    let dropdownItems
    const navItems: Section[] = [
        {
            title: "Backend",
            visible: true,
            icon: <StorageIcon />,
            dropdownItems: [

                {
                    icon: < TableChartIcon />,
                    title: "Tables",
                    items: tablesName,
                    authorizedSubscriptions: [
                        process.env.REACT_APP_BUSINESS_ID as string,
                        process.env.REACT_APP_STARTUP_ID as string,
                        process.env.REACT_APP_ENTERPRISE_ID as string,
                        process.env.REACT_APP_CORPORATION_ID as string,
                        process.env.REACT_APP_FREELANCER_ID as string,

                    ]
                },
                {
                    icon: < SummarizeIcon />,
                    title: "Reports",
                    items: reportsName,
                    authorizedSubscriptions: [
                        process.env.REACT_APP_BUSINESS_ID as string,
                        process.env.REACT_APP_STARTUP_ID as string,
                        process.env.REACT_APP_ENTERPRISE_ID as string,
                        process.env.REACT_APP_CORPORATION_ID as string,
                        process.env.REACT_APP_FREELANCER_ID as string,

                    ]
                },
                {
                    icon: < AccountTreeIcon />,
                    title: "Auto Rules",
                    items: autoRulesName,
                    authorizedSubscriptions: [
                        process.env.REACT_APP_BUSINESS_ID as string,
                        process.env.REACT_APP_STARTUP_ID as string,
                        process.env.REACT_APP_ENTERPRISE_ID as string,
                        process.env.REACT_APP_CORPORATION_ID as string,
                        process.env.REACT_APP_FREELANCER_ID as string,

                    ]
                },
                {
                    icon: < DashboardIcon />,
                    title: "Dashboards",
                    items: dashboardsName,
                    authorizedSubscriptions: [
                        process.env.REACT_APP_BUSINESS_ID as string,
                        process.env.REACT_APP_STARTUP_ID as string,
                        process.env.REACT_APP_ENTERPRISE_ID as string,
                        process.env.REACT_APP_CORPORATION_ID as string,
                        process.env.REACT_APP_FREELANCER_ID as string,

                    ]
                },
            ],
            simpleItems: [...simpleItemsDrawer, 
            {
                icon: < LabelIcon />,
                name: "Labels",
                path: `/${tenantId}/labels`,
                authorizedSubscriptions: [
                    process.env.REACT_APP_BUSINESS_ID as string,
                    process.env.REACT_APP_STARTUP_ID as string,
                    process.env.REACT_APP_ENTERPRISE_ID as string,
                    process.env.REACT_APP_CORPORATION_ID as string,
                    process.env.REACT_APP_FREELANCER_ID as string
                ]
            },
            {
                icon: < SettingsApplicationsIcon />,
                name: "Settings",
                path: `/${tenantId}/settings`,
                authorizedSubscriptions: [
                    process.env.REACT_APP_BUSINESS_ID as string,
                    process.env.REACT_APP_STARTUP_ID as string,
                    process.env.REACT_APP_ENTERPRISE_ID as string,
                    process.env.REACT_APP_CORPORATION_ID as string,
                    process.env.REACT_APP_FREELANCER_ID as string
                ]
            }
            ]
            ,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
            ]

        },
        {
            visible: permissionsList?.backend.roles.includes("CanManageEntities"),
            title: "Community",
            icon: <PeopleIcon />,
            dropdownItems: [

            ],
            simpleItems: [
                {
                    icon: < ApartmentIcon />,
                    name: "Accounts",
                    path: `/${tenantId}/accounts`,
                    authorizedSubscriptions: [
                        process.env.REACT_APP_BUSINESS_ID as string,
                        process.env.REACT_APP_STARTUP_ID as string,
                        process.env.REACT_APP_ENTERPRISE_ID as string,
                        process.env.REACT_APP_CORPORATION_ID as string,
                        process.env.REACT_APP_FREELANCER_ID as string,
                    ]
                },
                // {
                //     icon: < GroupIcon />,
                //     name: "Users",
                //     path: `/${tenantId}/users`,
                //     authorizedSubscriptions: [
                //         process.env.REACT_APP_BUSINESS_ID as string,
                //         process.env.REACT_APP_STARTUP_ID as string,
                //         process.env.REACT_APP_ENTERPRISE_ID as string,
                //         process.env.REACT_APP_CORPORATION_ID as string,
                //         process.env.REACT_APP_FREELANCER_ID as string,
                //     ]
                // },
                {
                    icon: < Diversity3Icon />,
                    name: "Contacts",
                    path: `/${tenantId}/contacts`,
                    authorizedSubscriptions: [
                        process.env.REACT_APP_BUSINESS_ID as string,
                        process.env.REACT_APP_STARTUP_ID as string,
                        process.env.REACT_APP_ENTERPRISE_ID as string,
                        process.env.REACT_APP_CORPORATION_ID as string,
                        process.env.REACT_APP_FREELANCER_ID as string,
                    ]
                },
                {
                    icon: < AccessibilityNewIcon />,
                    name: "End Users",
                    path: `/${tenantId}/endusers`,
                    authorizedSubscriptions: [
                        process.env.REACT_APP_BUSINESS_ID as string,
                        process.env.REACT_APP_STARTUP_ID as string,
                        process.env.REACT_APP_ENTERPRISE_ID as string,
                        process.env.REACT_APP_CORPORATION_ID as string,
                        process.env.REACT_APP_FREELANCER_ID as string,

                    ]
                }
            ]
            ,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
            ]

        },
        {
            visible: true,
            title: "Commerce",
            icon: <PaymentsIcon />,
            dropdownItems: [

            ],
            simpleItems: [

                {
                    icon: < CardMembershipIcon />,
                    name: "Subscriptions",
                    path: `/${tenantId}/commerce/subscriptions`,
                    authorizedSubscriptions: [
                        process.env.REACT_APP_BUSINESS_ID as string,
                        process.env.REACT_APP_STARTUP_ID as string,
                        process.env.REACT_APP_ENTERPRISE_ID as string,
                        process.env.REACT_APP_CORPORATION_ID as string,
                        // process.env.REACT_APP_FREELANCER_ID as string,

                    ]
                },
                {
                    icon: < SettingsIcon />,
                    name: "Settings",
                    path: `/${tenantId}/commerce/settings`,
                    authorizedSubscriptions: [
                        process.env.REACT_APP_BUSINESS_ID as string,
                        process.env.REACT_APP_STARTUP_ID as string,
                        process.env.REACT_APP_ENTERPRISE_ID as string,
                        process.env.REACT_APP_CORPORATION_ID as string,
                    ]
                },
            ]
            ,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
            ]

        },
        {
            visible: true,
            title: "Settings",
            icon: <SettingsIcon />,
            dropdownItems: [
                {
                    title: "CPanel",
                    icon: <ComputerIcon />,
                    items: [
                        {
                            path: `/${tenantId}/organization/cpanel/settings`,
                            name: "Configuracion",
                            authorizedSubscriptions: [process.env.REACT_APP_BUSINESS_ID as string,
                            process.env.REACT_APP_STARTUP_ID as string,
                            process.env.REACT_APP_FREELANCER_ID as string,
                            process.env.REACT_APP_ENTERPRISE_ID as string,
                            process.env.REACT_APP_CORPORATION_ID as string,]
                        },
                    ],
                    authorizedSubscriptions: [
                        process.env.REACT_APP_BUSINESS_ID as string,
                        process.env.REACT_APP_STARTUP_ID as string,
                        process.env.REACT_APP_FREELANCER_ID as string,
                        process.env.REACT_APP_ENTERPRISE_ID as string,
                        process.env.REACT_APP_CORPORATION_ID as string,
                    ]
                }
            ],
            simpleItems: [{
                icon: < PaymentIcon />,
                name: "Subscriptions",
                path: `/${tenantId}/subscriptions`,
                authorizedSubscriptions: [
                    process.env.REACT_APP_BUSINESS_ID as string,
                    process.env.REACT_APP_STARTUP_ID as string,
                    process.env.REACT_APP_FREELANCER_ID as string,
                    process.env.REACT_APP_ENTERPRISE_ID as string,
                    process.env.REACT_APP_CORPORATION_ID as string,
                ]
            },]
            ,
            authorizedSubscriptions: [
                process.env.REACT_APP_BUSINESS_ID as string,
                process.env.REACT_APP_STARTUP_ID as string,
                process.env.REACT_APP_FREELANCER_ID as string,
                process.env.REACT_APP_ENTERPRISE_ID as string,
                process.env.REACT_APP_CORPORATION_ID as string,
            ]

        }
    ]
    useEffect(() => {
        dispatch(getPermissions())
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar
                    sx={{ justifyContent: "space-between" }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                        }}
                    >
                        <MenuIcon
                            sx={{ color: "#555b6d" }}
                        />
                    </IconButton>
                    {
                        status === "loggedTenant" &&
                        <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center", color: "#555b6d" }}>
                  <IconButton onClick={handleOpenUpdates} sx={{ p: 0, marginRight: 2, cursor: 'pointer', "&:hover": { color: '#ffa400' } }}>
                    <NotificationsIcon />
                  </IconButton>

                  <Menu
                    sx={{ mt: "50px", color: "#555b6d" }}
                    id="menu-appbar"
                    anchorEl={anchorElUpdate}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={Boolean(anchorElUpdate)}
                    onClose={handleCloseUpdateMenu}
                  >
                    <Divider />
                    {updateLinks.map((linkItem, index) => (
                      <MenuItem key={index} component="a" href={linkItem.link} target="_blank" rel="noopener noreferrer" onClick={handleCloseUpdateMenu}>
                        {linkItem.version}
                      </MenuItem>
                    ))}
                  </Menu>
                            <a href='https://docs.nexusbackend.io/' target='_blank' style={{ flexGrow: 0, display: "flex", alignItems: "center", color: "#555b6d" }}>
                                <Tooltip title="Nexus Documentation" sx={{ mr: 2, cursor: 'pointer', "&:hover": { color: '#ffa400' } }}>
                                    <HelpOutlineIcon />
                                </Tooltip>
                            </a>
                            <Tooltip title="User settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt={`${name} ${lastname}`} src={`data:image/png;base64, ${profileImage}`} />
                                    <Typography variant='h6' sx={{ mx: "1rem", fontSize: 16 }}>{name}</Typography>
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "50px", color: "#555b6d" }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {/* <MenuItem onClick={() => { navigate("/user/profile/" + id) }}>
                                    <PersonIcon />
                                    <Typography textAlign="center" variant='caption' fontSize={14}>Profile</Typography>
                                </MenuItem> */}
                                <Divider />
                                <MenuItem onClick={handleBackends}>
                                    <StorageIcon />
                                    <Typography textAlign="center" variant='caption' fontSize={14}>Backends</Typography>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleLogout}>
                                    <LogoutIcon />
                                    <Typography textAlign="center" variant='caption' fontSize={14}>Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    }
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={{
                '& .MuiDrawer-paper': {
                    backgroundColor: "#2a3042"

                },
            }}>
                <DrawerHeader>
                    <Box
                        display={'flex'}
                        width={"100%"}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Box
                            height={40}
                            display={'flex'}
                            onClick={() => { navigate("/") }}
                            sx={{ cursor: 'pointer' }}
                        >
                            <img style={{ height: "100%" }} src="https://tesseractwebresources.blob.core.windows.net/webimages/Logo/TesseractLogo.png" alt="TLogo" />
                            <Typography display={open ? "block" : "none"} variant='h5' fontWeight={'500'} color={'#fff'}>Nexus</Typography>

                        </Box>
                    </Box>

                </DrawerHeader>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%'
                    }}
                >
                    <Box>

                        {
                            navItems.map((items, zIndex) => {
                                if (suscription?.id as string && items.visible)
                                    return <ListwithTitle subscription={(suscription?.id as string).toUpperCase()} key={zIndex} item={items} handleOpenDrawer={handleDrawerOpen} open={open} />
                            }
                            )
                        }
                    </Box>
                    <Box
                        sx={{
                            display: open ? "block" : "none"
                        }}
                    >
                        <Typography variant="h2" component="h3" sx={{ color: "#6a7187", fontSize: "0.875rem", textAlign: "center" }} gutterBottom >Powered By <a style={{ color: "inherit" }} href="https://tesseractsoftwares.com" target='_blank'>Tesseract</a> ®</Typography>
                    </Box>
                </Box>
            </Drawer >
            <Box component="main" sx={{ flexGrow: 1, mt: 6, minHeight: { xs: "calc(100vh - 54px)", md: "calc(100vh - 64px)" } }}>
                {children}
            </Box>
            {
                openAddTable === true
                && <AddTable open={openAddTable} setOpen={setOpenAddTable} />
            }
            {
                openAddAutoRule === true
                && <AddAutoRule open={openAddAutoRule} setOpen={setOpenAddAutoRule} />
            }
            {
                openAddReport === true
                && <AddReport open={openAddReport} setOpen={setOpenAddReport} />
            }
            {
                openAddDashboard === true
                && <AddDashboard open={openAddDashboard} setOpen={setOpenAddDashboard} />
            }
        </Box >
    );
}