// ViewSelector.tsx
import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { ViewModule as GridViewIcon, FormatListBulleted as ListViewIcon } from '@mui/icons-material';
import { GridCardTenant } from '../../components/TenantSelector/GridCardTenant';
import { DataGridTenant } from '../../components/TenantSelector/DataGridTenant';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { Avatar, Menu, MenuItem, Divider } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { startLogout } from '../../redux/auth/thunk';
import { cleanTenantData } from '../../redux/tenant/thunk';
import { cleanCPanelData } from '../../redux/cpanel/thunk';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { Tenant } from '../../models/Teanant';
import { getTenantsUser } from '../../redux/tenantsUser/thunk';
import { UpdatePopup } from '../../components/TenantSelector/UpdatePopup';
import axios from 'axios';
import { version } from 'os';

const url = process.env.REACT_APP_NEXUS_BACK_URL;

interface ViewSelectorProps {
  onChangeView: (view: 'grid' | 'list') => void;
}

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#fff",
  transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const TenantSelector: React.FC = () => {
    const [view, setView] = React.useState<'grid' | 'list'>('grid'); 
    const [open, setOpen] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElUpdate, setAnchorElUpdate] = React.useState<null | HTMLElement>(null);
    const dispatch = useAppDispatch();
    const { status, name, profileImage, lastname, id} = useAppSelector(state => state.auth)
    const { tenants } = useAppSelector(state => state.tenantsUser);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [version, setVersion] = React.useState<string>('');
    const [updateLink, setUpdateLink] = React.useState<string>('');
    const [content, setContent] = React.useState<string>('');
    const [imageURL, setImageURL] = React.useState<string>('');
    const [updateLinks, setUpdateLinks] = useState<{ version: string; link: string }[]>([]);
    
    React.useEffect(() => {
        dispatch(getTenantsUser());
    }, [dispatch]);

    const handleChangeView = (newView: 'grid' | 'list') => {
      setView(newView);
    };

    const handleLogout = () => {
      window.localStorage.removeItem("jwt")
      dispatch(startLogout());
      dispatch(cleanTenantData());
      dispatch(cleanCPanelData())
  }
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleOpenUpdates = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUpdate(event.currentTarget);
  };

      const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleCloseUpdateMenu = () => {
      setAnchorElUpdate(null);
  };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${url}/api/v1/NexusUpdate/UserLatest/${id}`);

          if (response.status === 200 && response.data.success) {
            const { version: fetchedVersion, imageURL: fetchedImageURL } = response.data.result.nexusUpdate;
            const { content: fetchedContent, updateLink: fetchedUpdateLink } = response.data.result.updateContents[0];
  
            setVersion(fetchedVersion);
            setImageURL(fetchedImageURL);
            setContent(fetchedContent);
            setUpdateLink(fetchedUpdateLink);
  
            setOpenModal(true);
          }
        } catch (error) {

        }
      };
  
      fetchData();
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${url}/api/v1/NexusUpdate/Latest`);

          if (response.status === 200 && response.data.success) {
            const links: { version: string; link: string }[] = [];
            response.data.result.forEach((result: any) => {
              result.updateContents.forEach((updateContent: any) => {
                links.push({ version: result.nexusUpdate.version, link: updateContent.updateLink });
              });
            });
            setUpdateLinks(links);
          }
        } catch (error) {

        }
      };
  
      fetchData();
    }, []);



    return (
      <>
        <Box p={2} sx={{ display: 'flex', flexDirection: 'column' }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar sx={{ justifyContent: "flex-end" }}>
              {status === "logged" &&
                <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center", color: "#555b6d" }}>

                  <IconButton onClick={handleOpenUpdates} sx={{ p: 0, marginRight: 2, cursor: 'pointer', "&:hover": { color: '#ffa400' } }}>
                    <NotificationsIcon />
                  </IconButton>

                  <Menu
                    sx={{ mt: "50px", color: "#555b6d" }}
                    id="menu-appbar"
                    anchorEl={anchorElUpdate}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={Boolean(anchorElUpdate)}
                    onClose={handleCloseUpdateMenu}
                  >
                    <Divider />
                    {updateLinks.map((linkItem, index) => (
                      <MenuItem key={index} component="a" href={linkItem.link} target="_blank" rel="noopener noreferrer" onClick={handleCloseUpdateMenu}>
                        {linkItem.version}
                      </MenuItem>
                    ))}
                  </Menu>

                  <a href='https://docs.nexusbackend.io/' target='_blank' style={{ flexGrow: 0, display: "flex", alignItems: "center", color: "#555b6d" }}>
                    <Tooltip title="Nexus Documentation" sx={{ mr: 2, cursor: 'pointer', "&:hover": { color: '#ffa400' } }}>
                      <HelpOutlineIcon />
                    </Tooltip>
                  </a>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={`${name} ${lastname}`} src={`data:image/png;base64, ${profileImage}`} />
                    <Typography variant='h6' sx={{ mx: "1rem", fontSize: 16 }}>{name}</Typography>
                    <ArrowDropDownIcon />
                  </IconButton>

                  <Menu
                    sx={{ mt: "50px", color: "#555b6d" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                      <LogoutIcon />
                      <Typography textAlign="center" variant='caption' fontSize={14}>Logout</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              }
            </Toolbar>
          </AppBar>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#2a3042', px: 2, py: 1, mb: 2, mt: 10 }}>
            <Typography variant="h6" component="span">Backends List</Typography>
            <Box>
              <Tooltip title="Grid View">
                <IconButton onClick={() => handleChangeView('grid')} color="primary">
                  <GridViewIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Grid List">
                <IconButton onClick={() => handleChangeView('list')} color="primary">
                  <ListViewIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>


          {view === 'grid' ? (
            <GridCardTenant tenants={tenants} />
          ) : (
            <DataGridTenant tenants={tenants} />
          )}
        </Box>
        
        

        {
          openModal && <UpdatePopup setModalOpen={setOpenModal} version={version} updateLink={updateLink} content={content} imageURL={imageURL}/>
        }
      </>
    );
  };